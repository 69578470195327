import React from 'react';
import {Layout} from "../containers/Layout";
import {ContactUs} from "../sections/ContactUs";
import {PageProps} from "gatsby";
import {H1} from "../components/NativeComponents";
import {TryApp} from "../sections/TryApp";
import {UTMs} from "../utms";

export type ContactProps = {} & PageProps;
export default function Contact({...props}: ContactProps) {
    return (
        <Layout {...props} meta={{title: 'Contact Us'}}>
            <div id={"contact"}/>
            <ContactUs TitleComponent={H1}/>
            <TryApp asSection utm={UTMs.subscribe_contact_tryapp}/>
        </Layout>
    )
}
