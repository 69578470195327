export enum UTM_Urls {
    subscribe_eab= 'https://unprn.us10.list-manage.com/subscribe'
}

export enum UTMs {
    subscribe_product_hero=         'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-product-hero&utm_medium=button&utm_campaign=unprn-01',
    subscribe_product_theapp=       'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-product-theapp&utm_medium=button&utm_campaign=unprn-01',
    subscribe_product_tryapp=       'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-product-tryapp&utm_medium=button&utm_campaign=unprn-01',
    subscribe_about_product=        'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-about-product&utm_medium=button&utm_campaign=unprn-01',
    subscribe_about_tryapp=         'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-about-tryapp&utm_medium=button&utm_campaign=unprn-01',
    subscribe_contact_tryapp=       'u=241faa8632901abf0f0fea122&id=64e1d7469a&utm_source=ws-contact-tryapp&utm_medium=button&utm_campaign=unprn-01',
    download_demo = ''
}
