import React from 'react';
import styled from 'styled-components';
import classNames from "classnames";
import {Colors, Fonts} from "../constants";


export type TwoToneTextProps = {
    className?: string;
};
export function TextLogo({className}: TwoToneTextProps) {
    return <Container className={classNames('text-logo', className)}>
        <span className='w1'>un</span>
        <span className='w2'>prn</span>
    </Container>;
}

const Container = styled.div`
  display: inline-block;
  font-family: ${ Fonts.Nunito };
  font-weight: bold;
  font-size: 1.1em;

  .w1 {
    color: white;
  }
  .w2 {
    color: ${Colors.Yellow} 
  }
`;
