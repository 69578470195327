import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Link, LinkProps} from "./Link";
import {IntrinsicElementProps} from "../utils";


export type CTAProps = {
    children: ReactNode;
    icon?: ReactNode;
    className?: string;
} & IntrinsicElementProps & LinkProps;
export function CTA({children, icon, className, ...props}: CTAProps) {
    let _icon: ReactNode;
    switch (typeof icon) {
        case "string":
            _icon = <i className={`cta_icon ${icon as string}`}></i>;
            break;
        case null:
            break;
        default:
            _icon = icon;
    }
    return <Container className={`cta ${className||''}`}{...props}>
        {children}
    </Container>;
}

const Container = styled(Link)`
    width: fit-content;
    display: block;
    padding: 2rem 2rem;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.8rem;
    text-decoration: none;
    color: white;
    
    background: linear-gradient(180.7deg, #FFC107 -53%, #F17404 112.95%, #E74102 220.33%);  
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: none;
    
    &:active, &:hover {
      border: none;
      background: linear-gradient(175.35deg, #FFC107 7.52%, #F17404 182.4%, #E74102 296.98%);
    } 
    
    .cta_icon {
      
    
    }
`;
