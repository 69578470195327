import * as React from 'react';
import {ReactNode} from 'react';
import styled from 'styled-components';
import {Fonts, Media, Palette} from '../constants';
import {CTA} from "./CTA";
import {UTM_Urls, UTMs} from "../utms";
import {getPlayStoreUrl} from "../utils";

export type EarlyAccessBetaFormProps = {
    utm: UTMs;
    className?: string;
    title?: ReactNode;
};
export default function EarlyAccessBetaForm({className, title, utm}: EarlyAccessBetaFormProps) {
    const downloadUrl = getPlayStoreUrl();
    return (
        <Container className={className}>
            <div className={'playstore'}>
                <Title>Available on Google Play</Title>
                <a target="_blank" href={downloadUrl}><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
            <div className={'register'}>
                {title || <>
                    <div>{'Not on Android?'}</div>
                    <div>{'Register for iOS and other devices'}</div>
                </>}
                <CTA to={`${UTM_Urls.subscribe_eab}?${utm}`} target={"_blank"}>Register Interest</CTA>
            </div>
        </Container>
    )
}

const Container = styled.div`
    display: flex ;
    flex-direction: column ;
    margin-top: 2.5em;
    @media (${Media.mobile}) {
      margin-top: 3.4em;
    }
  
  .playstore, .register {
    margin-bottom: 1rem;
    display: flex ;
    flex-direction: column ;
    align-items: center;
    text-align: center;
    img {
      width: 300px;
    }
  }
    
    .cta {
      line-height: 32px;
      font-size: 1.2rem;
      padding: 1rem;
      border-radius: 0.5rem;
    }
`;
const Title = styled.div`
    font-family: ${Fonts.Poppins};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: ${Palette.Default.color};
    @media (${Media.mobile}) {
      text-align: center ;
    }
`;
